<template>
  <div class="option-field">
    <div class="option-loader" v-if="loading">
      <i class="fa fa-spinner fa-spin"></i>
    </div>
    <base-input
      v-model="optionModel"
      v-if="option.value_type == OPTION_TYPE_NUMBER"
      type="number"
      step="0.001"
    ></base-input>

    <base-input v-if="option.value_type == OPTION_TYPE_CHECKBOX">
      <base-checkbox v-model="optionModel" class="mb-3" @change="updateField">
      </base-checkbox>
    </base-input>

    <base-input
      v-model="optionModel"
      v-if="option.value_type == OPTION_TYPE_STRING"
      type="string"
    ></base-input>

    <base-input v-if="option.value_type == OPTION_TYPE_WYSIWYG">
      <html-editor v-model="optionModel"> </html-editor>
    </base-input>

    <base-input v-if="option.value_type == OPTION_TYPE_OBJECT">
      <object-selector
        :object="option.value?.toString()"
        :objectType="option.data.ressource"
        :objectSearchField="option.data.searchField"
        :filterOrganization="option.organization.id"
        @objectChanged="
          (objectId) => {
            optionModel = objectId;
          }
        "
      />
    </base-input>
    <base-input v-if="option.value_type == OPTION_TYPE_MULTIPLE_OBJECTS">
      <objects-selector
        :objects="Array.isArray(option.value) ? option.value : []"
        :objectType="option.data.ressource"
        :objectSearchField="option.data.searchField"
        :filterOrganization="option.organization.id"
        @objectsChanged="
          (objects) => {
            optionModel = objects;
          }
        "
      />
    </base-input>
  </div>
</template>

<script>
import { cloneDeep, debounce } from "lodash";
import { Select, Option } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import {
  OPTION_TYPE_NUMBER,
  OPTION_TYPE_STRING,
  OPTION_TYPE_WYSIWYG,
  OPTION_TYPE_OBJECT,
  OPTION_TYPE_ARRAY,
  OPTION_TYPE_CHECKBOX,
  OPTION_TYPE_MULTIPLE_OBJECTS,
} from "@/constants/options";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue";
import ObjectSelector from "@/components/ObjectSelector.vue";
import ObjectsSelector from "@/components/ObjectsSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    [Select.name]: Select,
    [Option.name]: Option,
    BaseCheckbox,
    ObjectSelector,
    ObjectsSelector,
  },

  mixins: [],

  props: ["option"],

  data() {
    return {
      loading: false,
      optionModel: cloneDeep(this.option.value),
      OPTION_TYPE_NUMBER,
      OPTION_TYPE_STRING,
      OPTION_TYPE_WYSIWYG,
      OPTION_TYPE_OBJECT,
      OPTION_TYPE_ARRAY,
      OPTION_TYPE_CHECKBOX,
      OPTION_TYPE_MULTIPLE_OBJECTS,
    };
  },

  created() {},

  methods: {
    debouncedUpdate: debounce(function () {
      this.updateOption();
    }, 300),

    async updateOption() {
      this.loading = true;
      const optionData = cloneDeep(this.option);
      optionData.value = this.optionModel;
      try {
        await this.$store.dispatch("options/update", optionData);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },

  watch: {
    optionModel: {
      handler: "debouncedUpdate",
      deep: true,
    },
  },
};
</script>

<style>
.option-field {
  position: relative;
}
.option-loader {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}
</style>
